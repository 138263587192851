import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Covid from './covid';
import './scss/covid.scss';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Covid />
        </Route>
        </Switch>
    </Router>
  );
}

export default App;
