const axios = require('axios').default;

class Request {}

Request.get = function(url, opts) {
  if (!url) {
    throw new Error('Invalid url passed');
  }
  if (opts && !opts.headers) {
    opts.headers = {};
  }
  return axios.get(url, opts)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

Request.post = function(url, opts) {
  if (!url) {
    throw new Error('Invalid url passed');
  }
  if (opts && !opts.headers) {
    opts.headers = {};
  }
  return axios.post(url, opts)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

module.exports = Request;
