import React from 'react';
import Request from './request';
import NumberFormat from 'react-number-format';
import Regression from 'regression';
import './scss/covid.scss';

/**
 * Covid manager class
 */
class Covid extends React.Component {

  /**
   * Constructor
   * @param {Obj} props
   */
  constructor(props) {
    super(props);
    this.state = {
      confirmed_cases: '--',
      death_cases: '--',
      percentage_increase_global: '--',
      who: {
        'United States of America': {}
      },
      cdc: {

      },
      states: {

      },
      usa_daily_new_case: 0,
      usa_daily_perc_inc: 0
    };

    this.loadReport = this.loadReport.bind(this);
    this.loadHistoricalYesterday = this.loadHistoricalYesterday.bind(this);
    this.loadCDC = this.loadCDC.bind(this);

    const date = new Date(new Date().getTime() - 1000 * 60 * 5);
    const yesterdate = new Date(date.getTime() - 60000 * 60 * 24);
    this.loadReport(date)
      .then(() => {
        return this.loadHistoricalYesterday(yesterdate);
      });
    // this.loadCDC();

  }

  /**
   * loadCDC: will load the US data from the CDC
   */
  loadCDC() {
    Request.get('https://www.cdc.gov/coronavirus/2019-ncov/map-cases-us.json')
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * loadReport: loads the cached report from our CDN
   * @param {Date} date
   */
  loadReport(date) {
    const url = 'https://cdn.webupgrade.io/covid/reports/covid-report-' + date.getUTCMonth() + '.' + date.getUTCDate() + '.' + date.getUTCFullYear() + '.' + date.getUTCHours() + '.json';
    return Request.get(url)
      .then((response) => {
        const json = response;
        this.setState({
          confirmed_cases: json.confirmed_cases,
          death_cases: json.death_cases,
          new_cases: json.new_cases,
          who: json.who,
          states: json.states
        });
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        this.loadReport(new Date(date.getTime() - 60000 * 60));
      });
  }

  /**
   * loadHistoricalYesterday: will attempt to load the historical report from the day before
   * @param {Date} date
   */
  loadHistoricalYesterday(date) {
    const url = 'https://cdn.webupgrade.io/covid/reports/covid-report-' + date.getUTCMonth() + '.' + date.getUTCDate() + '.' + date.getUTCFullYear() + '.' + date.getUTCHours() + '.json';
    Request.get(url)
      .then((response) => {
        const json = response;

        const confirmed_cases = this.state.confirmed_cases;
        const change = (((confirmed_cases - json.confirmed_cases) / json.confirmed_cases) * 100.0).toFixed(2);

        const USA_confirmed_cases = this.state.who['United States of America'].confirmed;
        const USA_yesterday_cases = json.who['United States of America'].confirmed;
        const USA_perc_change = (((USA_confirmed_cases - USA_yesterday_cases) / USA_yesterday_cases) * 100.0).toFixed(2);

        const states = this.state.states;
        const yesterdayStates = json.states;
        for (const key in states) {
          const state = states[key];
          const yesterState = yesterdayStates[key];
          if (!state || !yesterState) {
            continue;
          }
          const change = state.confirmed - yesterState.confirmed;
          states[key].new_cases = change;
        }

        const countries = this.state.who;
        const yesterdayCountries = json.who;
        for (const key in countries) {
          const country = countries[key];
          const yesterCountry = yesterdayCountries[key];
          if (!country || !yesterCountry) {
            continue;
          }
          const change = country.confirmed - yesterCountry.confirmed;
          countries[key].new_cases = change;
        }

        this.setState({
          percentage_increase_global: change,
          new_cases: confirmed_cases - json.confirmed_cases,
          usa_daily_new_case: USA_confirmed_cases - USA_yesterday_cases,
          usa_daily_perc_inc: (USA_perc_change),
          states: states,
          who: countries
        });
      })
      .catch((err) => {

        console.log(err);
        this.loadHistoricalYesterday(new Date(date.getTime() - 60000 * 60));
      });
  }

  /**
   * render
   */
  render() {
    const countries = [];
    const states = [];
    const raw = [];

    for (const key in this.state.who) {
      raw.push(this.state.who[key]);
    }

    for (let i = 0; i < raw.length; ++i) {
      const data = raw[i];
      if (!data.name) {
        continue;
      }
      const country = data.name;
      const {
        confirmed,
        dead,
        new_cases,
        recovered
      } = data;

      countries.push(
        <tr key={data.name}>
          <td>{country}</td>
          <td><NumberFormat value={confirmed} displayType={'text'} thousandSeparator={true} /></td>
          <td><NumberFormat value={dead} displayType={'text'} thousandSeparator={true} /></td>
          <td><NumberFormat value={new_cases} displayType={'text'} thousandSeparator={true} /></td>
          <td>{recovered ? <NumberFormat value={recovered} displayType={'text'} thousandSeparator={true} /> : '--' }</td>
        </tr>
      );
    }
    for (const stateName in this.state.states) {
      const state = this.state.states[stateName];
      const {
        confirmed,
        dead,
        new_cases,
        recovered
      } = state;
      states.push(
        <tr key={stateName}>
          <td>{stateName}</td>
          <td><NumberFormat value={confirmed} displayType={'text'} thousandSeparator={true} /></td>
          <td><NumberFormat value={dead} displayType={'text'} thousandSeparator={true} /></td>
          <td>{new_cases ? <NumberFormat value={new_cases} displayType={'text'} thousandSeparator={true} /> : '--' }</td>
          <td>{recovered ? <NumberFormat value={recovered} displayType={'text'} thousandSeparator={true} /> : 0 }</td>
        </tr>
      );
    }
    return (
      <section className="content" style={{
          padding: '102px 30px 30px 30px'
        }}>

        <div className="row quick-stats">
          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.confirmed_cases} displayType={'text'} thousandSeparator={true} /></h2>
                <small>Global Confirmed Cases</small>
              </div>

            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.death_cases} displayType={'text'} thousandSeparator={true} /></h2>
                <small>Global Confirmed Deaths</small>
              </div>

            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.new_cases} displayType={'text'} thousandSeparator={true} /></h2>
                <small>Global Daily New Cases</small>
              </div>

            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2>{this.state.percentage_increase_global > 0 ? '+' : '-' }<NumberFormat value={this.state.percentage_increase_global} displayType={'text'} thousandSeparator={true} />%</h2>
                <small>Global Daily % Increase</small>
              </div>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Global Coronavirus Cases</h4>
                <h6 className="card-subtitle">Based upon the data from the World Health Organization, Coronavirus seems to be growing at an alarming rate.</h6>

                <div className="flot-chart covid-curved-line"></div>
                <div className="flot-chart-legends flot-chart-legends--curved"></div>
              </div>
            </div>
          </div>

          {/* <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Growth Rate</h4>
                <h6 class="card-subtitle">Commodo luctus nisi erat porttitor ligula eget lacinia odio semnec</h6>

                <div class="flot-chart flot-line"></div>
                <div class="flot-chart-legends flot-chart-legends--line"></div>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="row">
          <div className="card-body">
            <h4 className="card-title">Countries Affected</h4>
            <h6 className="card-subtitle">Hover over the country to see how many cases it has.</h6>

            <div className="widget-visitors__map covid-countries"></div>
          </div>
        </div> */}

        <div id="smt-130873652" style={{
          padding: '0px'
        }}></div>

        {this.state.who['United States of America'] && (
          <div className="row quick-stats">
          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.who['United States of America'].confirmed} displayType={'text'} thousandSeparator={true} /></h2>
                <small>USA Confirmed Cases</small>
              </div>

            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.who['United States of America'].dead} displayType={'text'} thousandSeparator={true} /></h2>
                <small>USA Confirmed Deaths</small>
              </div>

            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.usa_daily_new_case} displayType={'text'} thousandSeparator={true} /></h2>
                <small>USA Daily New Cases</small>
              </div>

            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="quick-stats__item">
              <div className="quick-stats__info">
                <h2><NumberFormat value={this.state.usa_daily_perc_inc} displayType={'text'} thousandSeparator={true} />%</h2>
                <small>USA Daily % Increase</small>
              </div>

            </div>
          </div>
        </div>
        )}

        {states.length > 0 && (
          <div className="card" id="usa-stats">
          <div className="card-body">
              <h4 className="card-title">United States</h4>
              <h6 className="card-subtitle">These metrics are pulled from the WHO and CDC.</h6>

              <div className="table-responsive data-table">
                <table id="data-table-usa" className="table">
                  <thead>
                  <tr>
                    <th>State</th>
                    <th>Confirmed Cases</th>
                    <th>Death Cases</th>
                    <th>New Cases</th>
                    <th>Recoveries</th>
                  </tr>
                  </thead>
                  <tbody>
                    {states}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
        )}

        {countries.length > 0 && (
          <div className="card" id="country-stats">
          <div className="card-body">
              <h4 className="card-title">Countries</h4>
              <h6 className="card-subtitle">These metrics are pulled from the WHO and CDC.</h6>

              <div className="table-responsive data-table">
                <table id="data-table-countries" className="table">
                  <thead>
                  <tr>
                    <th>Country</th>
                    <th>Confirmed Cases</th>
                    <th>Death Cases</th>
                    <th>New Cases</th>
                    <th>Recoveries</th>
                  </tr>
                  </thead>
                  <tbody>
                    {countries}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
        )}
      </section>
    );
  }
}

export default Covid;
